import React, { useEffect, useState } from 'react';
import Navigation2 from '../header/navigation/Navigation2';
import Web3 from 'web3';
import abi3 from '../../abi/nftMintABI';
import { useAccount } from 'wagmi';

const Nftminting = () => {


    const contract_address = process.env.REACT_APP_CONTRACT_ADDRESS;
    const web3 = new Web3("https://testnet.skalenodes.com/v1/lanky-ill-funny-testnet");
    web3.eth.setProvider(Web3.givenProvider);

    const [uri, seturi] = useState('');
    const mintingContract = new web3.eth.Contract(abi3, contract_address);
    const { address } = useAccount();
    
    const isWhitelisted = async (address) => {
        
        try {
            const whitelisted = await mintingContract.methods.whitelisted(address).call();
            console.log(whitelisted,'bool');
            
            return whitelisted;
        } catch (error) {
            console.error("Error checking whitelist status:", error);
            return false;
        }
    };
   


    const mintNFTs = async (e) => {
        e.preventDefault()
        const whitelisted = await isWhitelisted(address);
        if (!whitelisted) {
            alert("Address is not whitelisted and cannot mint NFTs.");
            return;
        }
        try {
            const mintNFT = mintingContract.methods.safeMint(uri).encodeABI();
            
            const mintNFTTx = await web3.eth.sendTransaction({
                from: address,
                to: contract_address,
                data: mintNFT,
                chainId: 37084624,
            });

            await mintNFTTx.wait();

        } catch (error) {
            console.error("", error);
            alert("" + error.message);
        }
    };
    return (
        <>
             <Navigation2 />
             <div className='simillar-cards-wrapper'>
             <div className="s-ellipse-1"></div>
          <div>
            <img className="cards-line-breaker" src="..\assets\images\footer\horizontal-line.png" alt="..." />
          </div>
          <h3 className="cards-title">Mint NFT</h3>
             <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      <div className="w-75 d-flex flex-column align-items-center mx-auto" style={{ minHeight: "80vh" }}>
       
        <div>
          <form onSubmit={mintNFTs}>
            <div className="form-group">
              <label className="text-white">URI</label>
              <input
                onChange={(e) => seturi(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Enter uri to be minted"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-5">Mint NFT</button>
          </form>
        </div>
      </div>
    </div>

             </div>
   
        </>

    );
};

export default Nftminting;
