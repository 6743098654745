import React, { useState } from "react";
import "./details.css";
import Footer from "../../components/footer/Footer";
import Navigation2 from "../../components/header/navigation/Navigation2";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAccount, useWalletClient } from "wagmi";
import Web3 from "web3";
import NFTcards from "../cards/NFTcards.jsx";
import { skaleNebula, skaleNebulaTestnet } from "viem/chains";

import abi2 from "../../abi/tokenABI.js";
import marketplaceJson from "../../newabi/marketplace.json";
import { toast } from "react-toastify";

const Details = () => {
  const location = useLocation();
  const { data } = location.state;

  const [nfts, setnfts] = useState([]);
  const [CardData, setCardData] = useState();
  const [Balance, setBalance] = useState(null);
  const [allowance, setAllowance] = useState(null);
  const { address } = useAccount();
  const { data: signer } = useWalletClient();

  const navigate = useNavigate();

  const marketplaceAddress = process.env.REACT_APP_marketplaceAddress;
  const token_contract_address = process.env.REACT_APP_tokenAddress;
  const mint_contract_address = process.env.REACT_APP_erc721;
  const web3 = new Web3(process.env.REACT_APP_RPC_URL);

  const marketContract = new web3.eth.Contract(
    marketplaceJson,
    marketplaceAddress
  );
  const tokencontract = new web3.eth.Contract(abi2, token_contract_address);

  const handleApprove = async () => {
    if (!address) {
      toast("Connect Wallet");
      return;
    }
    console.log(typeof data.price);
    console.log(marketplaceAddress);
    console.log(token_contract_address);

    const amountInWei = web3.utils.toWei(data.price, "ether");
    console.log(typeof amountInWei);
    console.log(tokencontract.methods);

    try {
      let transaction = {
        from: address,
        to: token_contract_address,
        chainId: 37084624,
        data: tokencontract.methods
          .approve(marketplaceAddress, amountInWei)
          .encodeABI(),
      };
      console.log("test");

      // Send the transaction
      const hash = await signer.sendTransaction(transaction);

      for (let index = 0; index > -1; index++) {
        console.log("Inside loop", hash);

        var receipt = await web3.eth.getTransactionReceipt(hash);
        console.log("receipt", receipt);
        if (receipt != null) {
          if (receipt.status) {
            toast("NFT approved successfully!");
            break;
          } else {
            toast("Transaction failed!");
            break;
          }
        }
      }
    } catch (error) {
      console.error("Error while approving tokens:", error);
      toast("Error while approving tokens: " + error.message);
    }
  };

  const buyCard = async () => {
    if (!address) {
      toast("Connect Wallet");
      return;
    }
    try {
      const listIndex = data?.id;
      console.log(listIndex);

      if (listIndex === undefined || listIndex === null) {
        toast("Invalid listing index");
        return;
      }
      const price = data?.price;
      console.log(price);
      const amountInWei = web3.utils.toWei(price, "ether");

      let transaction = {
        to: marketplaceAddress,
        chainId: 37084624,
        data: marketContract.methods.buyNft(listIndex, amountInWei).encodeABI(),
      };
      signer
        .sendTransaction(transaction)
        .then(async (hash) => {
          for (let index = 0; index > -1; index++) {
            console.log("Inside loop", hash);

            var receipt = await web3.eth.getTransactionReceipt(hash);
            console.log("receipt", receipt);
            if (receipt != null) {
              if (receipt.status == true) {
                // resolve({ success: true, hash: hash });
                toast("Transaction successful!");

                break;
              } else {
                // reject({ success: false });
                break;
              }
            }
          }
        })
        .catch((error) => {
          toast.error("Transaction Unsucessful!");

          // toast.error(error.shortMessage, {
          //     toastId: "mintError",
          // });
        });
    } catch (error) {
      console.error("Error While Buying:", error);
      toast.error("Error While Buying: " + error.message);
    }
  };

  const checkbalance = async () => {
    if (address) {
      const balance = await tokencontract.methods.balanceOf(address).call();
      const allowancess = await tokencontract.methods
        .allowance(address, marketplaceAddress)
        .call();
      setAllowance(Number(allowancess));
      const amountInEther = web3.utils.fromWei(balance, "ether");
      const balanceamount = Number(amountInEther).toFixed(0);
      setBalance(Number(balanceamount));
    }
  };
  useEffect(() => {
    checkbalance();
  }, [address]);

  console.log(Balance < data.price);
  console.log(typeof Balance);
  console.log(typeof data.price);
  console.log(typeof allowance);

  return (
    <>
      <Navigation2 />
      <section className="App text-white" style={{ width: "70%" }}>
        <div className="details-wrapper">
          <div className="d-ellipse-1"></div>
          <div className="d-ellipse-2"></div>
          <div className="row details-content">
            <div className="col-lg-6">
              <div className="d-flex justify-content-start align-items-center">
                <div className="NftCardImage">
                  <img
                    style={{ height: "450px", width: "350px" }}
                    className="img-fluid"
                    src={data?.image}
                    alt="NFT"
                  />
                  <img
                    className="img-fluid"
                    src="..\assets\images\detailscard\emptyCard.png"
                    alt="Background"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 details-r-side">
              <p className="pages-links">
                <span> Home / </span> <span>Collection /</span>{" "}
                {data?.name + " "}
              </p>
              <h3 className="d-title"> {data?.name} </h3>
              <p className="d-description mb-4">{data?.description}</p>
              <p className="d-description mb-4">
                SVET Balance:{" "}
                <span style={{ color: "#ffc810" }}>{Balance} sVets</span>
              </p>
              <span style={{ color: "#ffc810" }}>Price</span>: {data?.price}{" "}
              SVET
              {Balance < Number(data?.price) ? (
                <span className="d-btn ms-3">
                  <span style={{ color: "#ffc810" }}>Insufficient Balance</span>
                </span>
              ) : allowance < Number(data?.price) ? (
                <span className="d-btn ms-3" onClick={handleApprove}>
                  <span style={{ color: "#ffc810" }}>Approve</span>
                </span>
              ) : (
                <span className="d-btn ms-3" onClick={buyCard}>
                  <span style={{ color: "#ffc810" }}>Buy Now</span>
                </span>
              )}
              <br />
              <br />
            </div>
          </div>
        </div>
        <div className="category-wrapper mb-5">
          <div className="row category-inner-wrapper">
            {CardData?.attributes.map((data) => (
              <div className="col-lg-3" key={data?.superpower}>
                <p> {data?.superpower} </p>
                <p> {data?.rating} </p>
                <img
                  className="img-fluid"
                  src="..\assets\images\detailscard\Line 11.png"
                  alt="..."
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div className="simillar-cards-wrapper">
          <div className="s-ellipse-1"></div>
          <div>
            <img
              className="cards-line-breaker"
              src="..\assets\images\footer\horizontal-line.png"
              alt="..."
            />
          </div>
          <h3 className="cards-title">Similar items</h3>
          <div className="row">
            {nfts.map((item, index) => (
              <a
                key={index}
                className="col-lg-3 col-md-6 col-12 mt-4"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/detail", {
                    state: {
                      data: {
                        index: item.listCount,
                        url: item.uriData,
                        name: item.metadata.name,
                        description: item.metadata.description,
                        image: item.metadata.image,
                        status: data.status,
                        catergory: data.catergory,
                        cardColor: "Red",
                        price: item.listedData.price,
                      },
                    },
                  });
                  window.scrollTo(0, 0);
                }}
              >
                <NFTcards
                  id={item[0].tokenId}
                  url={item.uriData}
                  image={item.metadata.image}
                  status={data.status}
                  catergory={data.catergory}
                  cardColor={"Red"}
                  price={item.listedData.price}
                />
              </a>
            ))}
          </div>
        </div> */}
      </section>
      <Footer />
    </>
  );
};

export default Details;
