import React, { useState } from 'react';
import Navigation2 from '../header/navigation/Navigation2';
import Web3 from 'web3';
import abi3 from '../../abi/nftMintABI';
import { useAccount } from 'wagmi';

const WhiteLister = () => {


    const contract_address = process.env.REACT_APP_CONTRACT_ADDRESS;
    const distributor = process.env.REACT_APP_DISTRIBUTOR
    const web3 = new Web3("https://testnet.skalenodes.com/v1/lanky-ill-funny-testnet");
    web3.eth.setProvider(Web3.givenProvider);
    const [whitelistAddressInput, setWhitelistAddressInput] = useState('');
    const mintingContract = new web3.eth.Contract(abi3, contract_address);
    const { address } = useAccount();
    console.log(distributor, whitelistAddressInput);


    const whitelistAddress = async (e) => {
        e.preventDefault()
        if (!web3.utils.isAddress(whitelistAddressInput)) {
            alert("Invalid Ethereum address.");
            return;
        }
        try {
            const transferData = mintingContract.methods.whitelist(whitelistAddressInput).encodeABI();
          
            const whitelistTx = await web3.eth.sendTransaction({
                from: distributor,
                to: contract_address,
                data: transferData,
                chainId: 37084624,
            });

            await whitelistTx.wait();

        } catch (error) {
            console.error("Error while whitelisting address:", error);
            alert("Error while whitelisting address: " + error.message);
        }
    };
    return (
        <>
             <Navigation2 />
             <div className='simillar-cards-wrapper'>
             <div className="s-ellipse-1"></div>
          <div>
            <img className="cards-line-breaker" src="..\assets\images\footer\horizontal-line.png" alt="..." />
          </div>
          <h3 className="cards-title">White-Listing</h3>
              
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      <div className="w-75 d-flex flex-column  align-items-center mx-auto" style={{ minHeight: "80vh" }}>
        
        <div>
          <form onSubmit={whitelistAddress}>
            <div className="form-group">
              <label className="text-white">Address</label>
              <input
                onChange={(e) => setWhitelistAddressInput(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Enter address to be whitelisted"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-5">WhiteList</button>
          </form>
        </div>
      </div>
    </div>
             </div>

        </>

    );
};

export default WhiteLister;
