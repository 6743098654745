export const NFTs = [
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/characters/Arius.json",
    name: "Arius",
    type: "Bloodthirsty Villain",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/characters/Arius.webp",
    description:
      "Arius, a bloodthirsty villain in Super Vet's 3D world, hunts animals with razor-sharp claws, testing players' skill and courage.",
    attributes: [
      {
        trait_type: "Height",
        value: "6'5\"",
      },
      {
        trait_type: "Build",
        value:
          "Powerful and intimidating, with a muscular and imposing physique.",
      },
      {
        trait_type: "Hair Color",
        value: "Blood Red",
      },
      {
        trait_type: "Eye Color",
        value: "Fiery Orange",
      },
      {
        trait_type: "Skin Tone",
        value: "Pale, with a faint reddish hue.",
      },
      {
        trait_type: "Distinctive Features",
        value:
          "Razor-sharp claws, menacing teeth, and a fierce, predatory gaze.",
      },
      {
        trait_type: "Top",
        value: "Dark Grey or Black",
      },
      {
        trait_type: "Pants",
        value: "Dark Grey or Black",
      },
      {
        trait_type: "Shoes",
        value: "Black",
      },
      {
        trait_type: "Strength",
        value:
          "Superior strength and agility, expert tracking, and deadly claws.",
      },
      {
        trait_type: "Weakness",
        value:
          "Overly aggressive behavior, which can sometimes lead to strategic mistakes.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/characters/Bruce.json",
    name: "Bruce",
    type: "Lead Veterinarian",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/characters/Bruce.webp",
    description:
      "Dr. Bruce rescues pets like cats and dogs using his super-sensing powers.",
    attributes: [
      {
        trait_type: "Height",
        value: "6'2\"",
      },
      {
        trait_type: "Build",
        value: "Athletic with a fit, muscular physique.",
      },
      {
        trait_type: "Hair Color",
        value: "Dark Brown",
      },
      {
        trait_type: "Eye Color",
        value: "Blue, Emerald Green",
      },
      {
        trait_type: "Skin Tone",
        value: "Fair",
      },
      {
        trait_type: "Distinctive Features",
        value: "Strong jawline, high cheekbones, and a slight stubble.",
      },
      {
        trait_type: "Top",
        value: "Light Blue or Silver",
      },
      {
        trait_type: "Pants",
        value: "Dark Blue or Silver",
      },
      {
        trait_type: "Shoes",
        value: "White",
      },
      {
        trait_type: "Strength",
        value:
          "Exceptional diagnostic skills, unparalleled knowledge of animal anatomy, quick decision-making in critical situations.",
      },
      {
        trait_type: "Weakness",
        value:
          "Sometimes too focused on details, may overwork himself to ensure the well-being of the animals.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/characters/Darth.json",
    name: "Darth",
    type: "Ruthless Hunter",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/characters/Darth.webp",
    description:
      "Darth, a ruthless hunter, captures animals for their valuable parts, posing a cunning challenge for players to defeat.",
    attributes: [
      {
        trait_type: "Height",
        value: "6'3\"",
      },
      {
        trait_type: "Build",
        value: "Lean yet muscular, exuding an aura of strength and agility.",
      },
      {
        trait_type: "Hair Color",
        value: "Dark Brown",
      },
      {
        trait_type: "Eye Color",
        value: "Cold Grey",
      },
      {
        trait_type: "Skin Tone",
        value: "Pale",
      },
      {
        trait_type: "Distinctive Features",
        value: "Scarred face, intense gaze, and a menacing presence.",
      },
      {
        trait_type: "Top",
        value: "Light Blue or Silver",
      },
      {
        trait_type: "Pants",
        value: "Black or Dark Brown",
      },
      {
        trait_type: "Shoes",
        value: "Black",
      },
      {
        trait_type: "Strength",
        value:
          "Skilled in tracking and hunting, highly resourceful, and has an extensive knowledge of traps and weaponry.",
      },
      {
        trait_type: "Weakness",
        value:
          "Overconfidence, lack of empathy, and reliance on physical traps which can sometimes be outsmarted.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/characters/Ivy.json",
    name: "Ivy",
    type: "Herbal Medicine Expert Vet",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/characters/Ivy.webp",
    description:
      "In Super Vet's 3D world, Dr. Ivy creates potent plant potions to heal animals and fend off villains with her herbal medicine expertise.",
    attributes: [
      {
        trait_type: "Height",
        value: "5'7\"",
      },
      {
        trait_type: "Build",
        value: "Slim and agile with an elegant appearance.",
      },
      {
        trait_type: "Hair Color",
        value: "Dark Green",
      },
      {
        trait_type: "Eye Color",
        value: "Emerald Green",
      },
      {
        trait_type: "Skin Tone",
        value: "Fair",
      },
      {
        trait_type: "Distinctive Features",
        value: "Graceful demeanor, vibrant green hair, and a soothing aura.",
      },
      {
        trait_type: "Top",
        value: "Green or Earth-Toned",
      },
      {
        trait_type: "Pants",
        value: "Green or Earth-Toned",
      },
      {
        trait_type: "Shoes",
        value: "Green",
      },
      {
        trait_type: "Jacket",
        value: "Light Green or Earth-Toned",
      },
      {
        trait_type: "Strength",
        value:
          "Expertise in herbal medicine, ability to create effective potions, and knowledge of various plants and their properties.",
      },
      {
        trait_type: "Weakness",
        value:
          "Limited combat skills, reliant on availability of specific plants for potion-making.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/characters/Nina.json",
    name: "Nina",
    type: "Lightning-Fast Super Vet",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/characters/Nina.webp",
    description:
      "Sister Nina can track animals by smell, rescue pregnant ones, perform expert surgeries, and move at lightning speed.",
    attributes: [
      {
        trait_type: "Height",
        value: "5'8\"",
      },
      {
        trait_type: "Build",
        value: "Athletic with a lean and agile physique.",
      },
      {
        trait_type: "Hair Color",
        value: "Platinum Blonde",
      },
      {
        trait_type: "Eye Color",
        value: "Ice Blue",
      },
      {
        trait_type: "Skin Tone",
        value: "Light Olive",
      },
      {
        trait_type: "Distinctive Features",
        value:
          "Sharp, focused eyes, high ponytail, and a calm, confident demeanor.",
      },
      {
        trait_type: "Top",
        value: "Light Blue or Silver",
      },
      {
        trait_type: "Pants",
        value: "Black or Dark Brown",
      },
      {
        trait_type: "Shoes",
        value: "Black",
      },
      {
        trait_type: "Strength",
        value:
          "Speed, precision, ability to remain calm under pressure, expert surgical techniques.",
      },
      {
        trait_type: "Weakness",
        value:
          "High-speed movements may lead to occasional misjudgments in extremely chaotic environments.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/characters/Steelshot.json",
    name: "Steelshot",
    type: "Superhuman Strength Vet",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/characters/Steel+Shot.webp",
    description:
      "Steelshot boasts super strength, magnetic abilities, laser eyes, enhanced stamina, and wall-crushing steel hands.",
    attributes: [
      {
        trait_type: "Height",
        value: "6'5\"",
      },
      {
        trait_type: "Build",
        value: "Muscular and imposing, with a broad and powerful physique.",
      },
      {
        trait_type: "Hair Color",
        value: "Black",
      },
      {
        trait_type: "Eye Color",
        value: "Steel Grey",
      },
      {
        trait_type: "Skin Tone",
        value: "Deep tan",
      },
      {
        trait_type: "Distinctive Features",
        value: "Prominent muscles, strong jawline, and a commanding presence.",
      },
      {
        trait_type: "Top",
        value: "Dark Grey or Black",
      },
      {
        trait_type: "Pants",
        value: "Dark Grey or Black",
      },
      {
        trait_type: "Shoes",
        value: "Black",
      },
      {
        trait_type: "Strength",
        value:
          "Speed, endurance, adaptability to various terrains, and rapid response to emergencies.",
      },
      {
        trait_type: "Weakness",
        value:
          "Extreme speed may lead to occasional misjudgments in highly complex situations.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/characters/Wolf.json",
    name: "Wolf",
    type: "Lightning-Fast Super Vet",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/characters/Wolf.webp",
    description:
      "Dr. Wolf excels in saving wild animals with his speed, high stamina, and long jumps.",
    attributes: [
      {
        trait_type: "Height",
        value: "6'0\"",
      },
      {
        trait_type: "Build",
        value: "Athletic with a strong, muscular build.",
      },
      {
        trait_type: "Hair Color",
        value: "Dark Grey",
      },
      {
        trait_type: "Eye Color",
        value: "Amber",
      },
      {
        trait_type: "Skin Tone",
        value: "Medium Tan",
      },
      {
        trait_type: "Distinctive Features",
        value:
          "Sharp facial features, defined muscles, and a rugged appearance.",
      },
      {
        trait_type: "Top",
        value: "Dark Grey or Grey",
      },
      {
        trait_type: "Pants",
        value: "Dark Grey or Black",
      },
      {
        trait_type: "Shoes",
        value: "Black",
      },
      {
        trait_type: "Strength",
        value:
          "Speed, endurance, adaptability to various terrains, and rapid response to emergencies.",
      },
      {
        trait_type: "Weakness",
        value:
          "Extreme speed may lead to occasional misjudgments in highly complex situations.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/items/Fly.json",
    name: "Fly",
    type: "potion",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/items/Fly.webp",
    description:
      "Lets characters fly, helping them reach high places and avoid obstacles.",
    attributes: [
      {
        trait_type: "Effect",
        value:
          "Grants the ability to fly temporarily, allowing players to bypass obstacles and explore hard-to-reach areas.",
      },
      {
        trait_type: "Duration",
        value: "3 minutes",
      },
      {
        trait_type: "Rarity",
        value: "Rare",
      },
      {
        trait_type: "Color",
        value: "Blue Sky",
      },
      {
        trait_type: "Appearance",
        value: "Shimmering blue potion with cloud patterns.",
      },
      {
        trait_type: "Usage",
        value:
          "Essential for recovery after taking damage or sustaining injuries.",
      },
      {
        trait_type: "In Game Benefits",
        value:
          "Increased exploration capabilities and the ability to avoid ground-based challenges.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/items/Health.json",
    name: "Health",
    type: "potion",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/items/Health.webp",
    description:
      "Restores health and helps characters recover from injuries, making them feel better and ready for action.",
    attributes: [
      {
        trait_type: "Effect",
        value:
          "Restores health points, healing the player and repairing damage sustained during missions.",
      },
      {
        trait_type: "Duration",
        value: "Instant effect",
      },
      {
        trait_type: "Rarity",
        value: "Common",
      },
      {
        trait_type: "Color",
        value: "Red",
      },
      {
        trait_type: "Appearance",
        value: "A bright red potion with a heart symbol.",
      },
      {
        trait_type: "Usage",
        value:
          "Essential for recovery after taking damage or sustaining injuries.",
      },
      {
        trait_type: "In Game Benefits",
        value: "Immediate restoration of health and increased survivability.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/items/Power.json",
    name: "Power",
    type: "potion",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/items/Power.webp",
    description:
      "Enhances strength to lift heavy objects and smash through barriers.",
    attributes: [
      {
        trait_type: "Effect",
        value:
          "Increases the player’s strength and combat abilities, allowing for more effective fighting and interactions.",
      },
      {
        trait_type: "Duration",
        value: "5 minutes",
      },
      {
        trait_type: "Rarity",
        value: "Rare",
      },
      {
        trait_type: "Color",
        value: "Purple",
      },
      {
        trait_type: "Appearance",
        value: "A deep purple potion with a muscular emblem.",
      },
      {
        trait_type: "Usage",
        value:
          "Useful for overcoming tough enemies and performing powerful actions.",
      },
      {
        trait_type: "In Game Benefits",
        value: "Enhanced combat effectiveness and increased physical prowess.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/items/Speed.json",
    name: "Speed",
    type: "potion",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/items/Speed.webp",
    description:
      "Makes characters move faster, allowing them to travel quickly and handle challenges with ease.",
    attributes: [
      {
        trait_type: "Effect",
        value:
          "Increases the player's speed, allowing them to move faster in the game and complete missions more quickly.",
      },
      {
        trait_type: "Rarity",
        value: "Common",
      },
      {
        trait_type: "Duration",
        value: "5 Minutes",
      },
      {
        trait_type: "Color",
        value: "Electric Blue",
      },
      {
        trait_type: "Appearance",
        value: "A glowing blue vial with lightning bolt symbols.",
      },
      {
        trait_type: "Usage",
        value:
          "Useful for escaping from danger, completing time-sensitive missions, and reaching distant locations faster.",
      },
      {
        trait_type: "In Game Benefits",
        value: "Enhanced mobility and quicker reactions during gameplay.",
      },
    ],
  },
  {
    uri: "https://supervet.s3.us-east-2.amazonaws.com/nfts/jsons/items/Stamina.json",
    name: "Stamina",
    type: "potion",
    image:
      "https://supervet.s3.us-east-2.amazonaws.com/nfts/images/items/Stamina.webp",
    description:
      "Boosts energy so characters can keep going without getting tired.",
    attributes: [
      {
        trait_type: "Effect",
        value:
          "Boosts the player’s stamina, reducing fatigue and allowing for prolonged action and movement.",
      },
      {
        trait_type: "Duration",
        value: "7 Minutes",
      },
      {
        trait_type: "Rarity",
        value: "Uncommon",
      },
      {
        trait_type: "Color",
        value: "Green",
      },
      {
        trait_type: "Appearance",
        value: "A vibrant green liquid with a leaf emblem.",
      },
      {
        trait_type: "Usage",
        value:
          "Ideal for extended missions and activities requiring sustained effort.",
      },
      {
        trait_type: "In Game Benefits",
        value:
          "Increased endurance and reduced stamina depletion during prolonged activities.",
      },
    ],
  },
];
