import React, { useState } from "react";
import Web3 from "web3";
import { useAccount } from "wagmi";
import abi from "../../abi/marketplaceABI";
import Navigation2 from "../header/navigation/Navigation2";
import { useParams } from "react-router-dom";
const UserLister = () => {
  const { id } = useParams();
  console.log(id, "id");

  const contract_address = process.env.REACT_APP_CONTRACT_ADDRESS;

  const [price, setprice] = useState(0);
  const [tokenId, settokenId] = useState(id);
  const marketplaceAddress = process.env.REACT_APP_MARKETPLACE_ADDRESS;

  const web3 = new Web3(
    "https://testnet.skalenodes.com/v1/lanky-ill-funny-testnet"
  );
  web3.eth.setProvider(Web3.givenProvider);

  const { address } = useAccount();

  const marketContract = new web3.eth.Contract(abi, marketplaceAddress);

  const listNFT = async (e) => {
    e.preventDefault();

    if (!address) {
      alert("Connect Wallet");
      return;
    }

    try {
      const _mintContract = contract_address;
      const _price = price; // Assuming price is already in Wei format
      const _tokenId = id;

      let transaction = {
        from: address,
        to: marketplaceAddress,
        chainId: 37084624,
        data: marketContract.methods
          .listNft(_mintContract, _price, _tokenId)
          .encodeABI(),
      };

      // Send the transaction
      const hash = await web3.eth.sendTransaction(transaction);

      for (let index = 0; index > -1; index++) {
        console.log("Inside loop", hash);

        var receipt = await web3.eth.getTransactionReceipt(
          hash.transactionHash
        );
        console.log("receipt", receipt);
        if (receipt != null) {
          if (receipt.status) {
            alert("NFT listed successfully!");
            break;
          } else {
            alert("Transaction failed!");
            break;
          }
        }
      }
    } catch (error) {
      console.error("Error while listing NFT:", error);
      alert("Error while listing NFT: " + error.message);
    }
  };

  return (
    <>
      <Navigation2 />
      <div className="simillar-cards-wrapper">
        <div>
          <img
            className="cards-line-breaker"
            src="..\assets\images\footer\horizontal-line.png"
            alt="..."
          />
        </div>
        <h3 className="cards-title">Listing NFTs</h3>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div
            className="w-75 d-flex flex-column align-items-center mx-auto"
            style={{ minHeight: "80vh" }}
          >
            <div>
              <form onSubmit={listNFT}>
                <div className="form-group">
                  <label className="text-white">Price</label>
                  <input
                    onChange={(e) => setprice(e.target.value)}
                    type="number"
                    className="form-control"
                    placeholder="Enter Price for NFT"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="text-white">TokenId</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Token Id"
                    required
                    value={id}
                  />
                </div>
                <button type="submit" className="btn btn-primary mt-5">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLister;
