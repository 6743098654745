import React, { useEffect, useState } from 'react';
import Navigation2 from '../header/navigation/Navigation2';
import Web3 from 'web3';
import abi3 from '../../abi/nftMintABI';
import { useAccount } from 'wagmi';
import NFTcards from '../../pages/cards/NFTcards';
import abi from '../../abi/marketplaceABI';
import { Link } from 'react-router-dom';

const UserNFTs = () => {

    const contract_address = process.env.REACT_APP_CONTRACT_ADDRESS;
    const marketplaceAddress = process.env.REACT_APP_MARKETPLACE_ADDRESS

    const web3 = new Web3("https://testnet.skalenodes.com/v1/lanky-ill-funny-testnet");
    web3.eth.setProvider(Web3.givenProvider);

    const mintingContract = new web3.eth.Contract(abi3, contract_address);
    

    const { address } = useAccount();
    const [tokenData, setTokenData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isapproved, setisapproved] = useState(false)

    useEffect(() => {
        const fetchTokens = async () => {
            if (!address) {
                alert("Connect Wallet");
                return;
            }
            
            try {
              
                const tokenIds = await mintingContract.methods.getTokensByOwner(address).call();
                const uris = await Promise.all(
                    tokenIds.map(async (tokenId) => {
                        return await mintingContract.methods.tokenURI(tokenId).call();
                    })
                );

                const tokenData = await Promise.all(
                    uris.map(async (uri) => {
                        try {
                            const response = await fetch(uri);
                            const metadata = await response.json();
                            return metadata;
                        } catch (error) {
                            console.error('Error fetching metadata for NFT:', uri, error);
                            return null;
                        }
                    })
                );
                setTokenData(tokenData.filter(data => data !== null));
            } catch (error) {
                console.error("Error fetching tokens:", error);
                alert("Error fetching tokens: " + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTokens();
    }, [address]);
    const approveNFT = async (tokenId) => {
        try {
            const approveNFTData = mintingContract.methods.approve(marketplaceAddress, tokenId).encodeABI();
            
            const approveNFTTx = await web3.eth.sendTransaction({
                from: address,
                to: contract_address,
                data: approveNFTData,
                chainId: 37084624,  // Ensure this matches your chain ID
            });
    
            await approveNFTTx.wait();
            alert("NFT approved successfully!");
    
            // Update the approved status in the tokenData
            setTokenData(prevData =>
                prevData.map(item =>
                    item.tokenId === tokenId ? { ...item, isApproved: true } : item
                )
            );
        } catch (error) {
            console.error("Error approving NFT:", error);
            alert("Error approving NFT: " + error.message);
        }
        finally{
            setisapproved(true)
        }
    };
    

  return (
    <div style={{color:"white",minHeight:"100vh"}}>
        <Navigation2/>
                <div  className=''>
            {loading ? (
                <p>Loading tokens...</p>
            ) : (
                <div className="simillar-cards-wrapper">
          <div className="s-ellipse-1"></div>
          <div>
            <img className="cards-line-breaker" src="..\assets\images\footer\horizontal-line.png" alt="..." />
          </div>
          <h3 className="cards-title">User NFTs</h3>
          <div style={{}} className="row">
            {tokenData.map((item, index) => (
                <>
                
              <a
                key={index}
                className="col-lg-3 col-md-6 col-12 mt-4"
                style={{ cursor: "pointer" }}
            
              >
                <NFTcards
                  id={item[0].tokenId}
                  url={item.uriData}
                  image={item.metadata.image}
                  cardColor={"Red"}
                  price={item.listedData.price}
                />
              </a>
              {isapproved?(
                <>
                <Link to={`/userlister/${item[0].tokenId}`}>
                
                 <button>
               List
              </button>
                </Link>
                
                </>
              ):(
                <>
                 <button onClick={()=>approveNFT(item[0].tokenId)}>
               Approve
              </button>
                </>
              )}
             
                </>
            ))}
          </div>
        </div>
            )}
        </div>
    </div>
  )
}

export default UserNFTs