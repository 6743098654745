import "./App.css";
import "aos/dist/aos.css";
import "@rainbow-me/rainbowkit/styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import { useEffect, useState } from "react";
import Into from "./components/loader/Into";
import Details from "./pages/details/Details";
import Cards from "./pages/cards/Cards";
import { RainbowKitProvider, getDefaultConfig } from "@rainbow-me/rainbowkit";
import ArticleD from "./pages/articlesDetails/ArticleD";
import AOS from "aos";
import { WagmiProvider } from "wagmi";
import { skaleNebulaTestnet } from "wagmi/chains";
import About from "./components/about/About";
import AdminLister from "./components/admin-lister/AdminLister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import WhiteLister from "./components/Whitelisting/Whiter";
import Nftminting from "./components/Minter/Nftminting";
import UserNFTs from "./components/uerCards/UserNFTs";
import UserLister from "./components/UserList/UserLister";
// import AdminLister from "./admin/admin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MintedNfts from "./components/mintednfts/mintednfts";

function App() {
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(true);
  const [count, setCount] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setFlag(false);
    }, 3000);
  }, []);
  useEffect(() => {
    setCount(1);
  }, [loading, flag]);

  AOS.init({
    once: true,
    anchorPlacement: "top-bottom",
  });

  const config = getDefaultConfig({
    appName: "supervetmarketplace",
    projectId: "5d4c013aa99e6070e999d48345ffc478",
    chains: [skaleNebulaTestnet],
    ssr: false,
  });
  const queryClient = new QueryClient();

  return (
    <>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            <BrowserRouter>
              <ToastContainer />
              <Routes>
                <Route path="/" element={<Into />}></Route>
                <Route path="/home" element={<Home />}></Route>
                <Route path="/detail" element={<Details />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route path="/collections" element={<Cards />}></Route>
                <Route path="/article-detail" element={<ArticleD />}></Route>
                <Route path="/admin" element={<AdminLister />}></Route>
                <Route path="/adminlister" element={<AdminLister />}></Route>
                <Route path="/whiteList" element={<WhiteLister />}></Route>
                <Route path="/mintRoute" element={<Nftminting />}></Route>
                <Route path="/myCards" element={<UserNFTs />}></Route>
                <Route path="/userlister/:id" element={<UserLister />}></Route>
                <Route path="/admin" element={<AdminLister />}></Route>
                <Route path="/mintednfts" element={<MintedNfts />}></Route>
              </Routes>
            </BrowserRouter>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </>
  );
}

export default App;
