import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { useAccount, useWalletClient } from "wagmi";
import abi from "../../abi/marketplaceABI";
import mintAbi from "../../abi/nftMintABI";
import Navigation2 from "../header/navigation/Navigation2";
import NFTcards from "../../pages/cards/NFTcards";
import { NFTs } from "../nfts";
import axios from "axios";
const AdminLister = () => {
  const [mintedNfts, setMintedNfts] = useState([]);
  const [price, setPrice] = useState(0);

  const web3 = new Web3(process.env.REACT_APP_RPC_URL);
  // web3.eth.setProvider(Web3.givenProvider);
  const { data: signer } = useWalletClient();
  const { address } = useAccount();

  const marketContract = new web3.eth.Contract(
    abi,
    process.env.REACT_APP_marketplaceAddress
  );
  const mintContract = new web3.eth.Contract(
    mintAbi,
    process.env.REACT_APP_erc721
  );

  const handleMint = async (item) => {
    console.log(item.uri);

    try {
      let transaction = {
        from: address,
        to: process.env.REACT_APP_erc721,
        chainId: 37084624,
        data: mintContract.methods.safeMint(item.uri).encodeABI(),
      };

      // Send the transaction
      const hash = await signer.sendTransaction(transaction);

      for (let index = 0; index > -1; index++) {
        console.log("Inside loop", hash);

        var receipt = await web3.eth.getTransactionReceipt(hash);
        console.log("receipt", receipt);
        if (receipt != null) {
          if (receipt.status) {
            alert("NFT minted successfully!");
            break;
          } else {
            alert("Transaction failed!");
            break;
          }
        }
      }
    } catch (error) {
      console.error("Error while minting NFT:", error);
      alert("Error while minting NFT: " + error.message);
    }
  };
  const handleApprove = async (item) => {
    console.log(item.uri);

    try {
      let transaction = {
        from: address,
        to: process.env.REACT_APP_erc721,
        chainId: 37084624,
        data: mintContract.methods
          .approve(process.env.REACT_APP_marketplaceAddress, item.tokenid)
          .encodeABI(),
      };

      // Send the transaction
      const hash = await signer.sendTransaction(transaction);

      for (let index = 0; index > -1; index++) {
        console.log("Inside loop", hash);

        var receipt = await web3.eth.getTransactionReceipt(hash);
        console.log("receipt", receipt);
        if (receipt != null) {
          if (receipt.status) {
            alert("NFT approved successfully!");
            break;
          } else {
            alert("Transaction failed!");
            break;
          }
        }
      }
    } catch (error) {
      console.error("Error while approving NFT:", error);
      alert("Error while approving NFT: " + error.message);
    }
  };

  const handleList = async (item) => {
    console.log(item);
    try {
      console.log(process.env.REACT_APP_erc721);
      console.log(price);
      console.log(item.tokenid);

      let transaction = {
        from: address,
        to: process.env.REACT_APP_marketplaceAddress,
        chainId: 37084624,
        data: marketContract.methods
          .listNft(process.env.REACT_APP_erc721, price, item.tokenid)
          .encodeABI(),
      };

      // Send the transaction
      const hash = await signer.sendTransaction(transaction);

      for (let index = 0; index > -1; index++) {
        console.log("Inside loop", hash);

        var receipt = await web3.eth.getTransactionReceipt(hash);
        console.log("receipt", receipt);
        if (receipt != null) {
          if (receipt.status) {
            alert("NFT listed successfully!");
            break;
          } else {
            alert("Transaction failed!");
            break;
          }
        }
      }
    } catch (error) {
      console.error("Error while listing NFT:", error);
      alert("Error while listing NFT: " + error.message);
    }
  };

  const getAllListedNft = async () => {
    const GetAllLandNfts = await marketContract.methods
      .getAllNftListedNfts()
      .call();
    console.log(GetAllLandNfts);
  };

  useEffect(() => {
    getAllListedNft();
  }, []);

  const getAllMintedNfts = async () => {
    const totalSupply = await mintContract.methods.totalSupply().call();
    var temp = [];
    for (let i = 1; i <= totalSupply; i++) {
      const tokenuri = await mintContract.methods.tokenURI(i).call();
      const ownerof = await mintContract.methods.ownerOf(i).call();
      const getApproved = await mintContract.methods.getApproved(i).call();
      const islisted = await marketContract.methods
        .userNftListings(process.env.REACT_APP_erc721, i)
        .call();
      console.log(islisted.listed);

      axios.get(tokenuri).then((response) => {
        console.log(response.data);
        response.data.tokenid = i;
        response.data.ownerof = ownerof;
        response.data.isListed = islisted.listed;
        response.data.approved = getApproved;

        temp.push(response.data);
      });
    }
    console.log(temp);
    setMintedNfts(temp);
  };
  useEffect(() => {
    getAllMintedNfts();
  }, []);

  return (
    <>
      <Navigation2 />
      <div className="simillar-cards-wrapper">
        <div>
          <img
            className="cards-line-breaker"
            src="..\assets\images\footer\horizontal-line.png"
            alt="..."
          />
        </div>
        <h3 className="cards-title">Minting NFTs</h3>

        <div className="container">
          <div className="row">
            {NFTs.map((item, index) => (
              <a
                key={index}
                className="col-lg-3 col-md-6 col-12 mt-4"
                style={{ cursor: "pointer" }}
              >
                <a className="col-lg-3 col-md-6 col-12 mt-4">
                  <div class="card card-contents p-3">
                    <div>
                      <img src={item?.image} class="card-img-top img-fluid" />
                    </div>
                    <div class="card-body  card-content-inner mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <span className="card-name">{item?.name}</span>
                          <span className="card-color">{item?.cardColor}</span>
                        </div>
                        <div>
                          <span className="human-body"> Characters </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <span className="card-art"></span>
                          <span className="card-designer">Comming Soon</span>
                        </div>
                        <div>
                          <span className="card-price">Price : </span>
                          <span className="card-value">225SVET </span>
                        </div>
                      </div>
                      <div className="d-flex justify-center mt-2">
                        <button
                          onClick={() => handleMint(item)}
                          className="list-for-sale"
                        >
                          Mint
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </a>
            ))}
          </div>
        </div>
        <h3 className="cards-title">Minted NFTs</h3>

        <div className="container">
          <div className="row">
            {mintedNfts &&
              mintedNfts.length > 0 &&
              mintedNfts.map((item, index) => (
                <a
                  key={index}
                  className={
                    item.ownerof == address
                      ? "col-lg-3 col-md-6 col-12 mt-4"
                      : "col-lg-3 col-md-6 col-12 mt-4 d-none"
                  }
                  style={{ cursor: "pointer" }}
                >
                  <a className="col-lg-3 col-md-6 col-12 mt-4">
                    <div class="card card-contents p-3">
                      <div>
                        <img src={item?.image} class="card-img-top img-fluid" />
                      </div>
                      <div class="card-body  card-content-inner mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <span className="card-name">{item?.name}</span>
                            <span className="card-color">
                              {item?.cardColor}
                            </span>
                          </div>
                          <div>
                            <span className="human-body"> Characters </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <span className="card-art"></span>
                            <span className="card-designer">Comming Soon</span>
                          </div>
                          <div>
                            <span className="card-price">Price : </span>
                            <span className="card-value">225SVET </span>
                          </div>
                        </div>

                        {item.isListed ? (
                          <></>
                        ) : (
                          <div className="d-flex justify-center mt-2">
                            {item.approved ==
                            0x0000000000000000000000000000000000000000 ? (
                              <button
                                onClick={() => handleApprove(item)}
                                className="list-for-sale"
                              >
                                approve
                              </button>
                            ) : (
                              <>
                                <input
                                  type="number"
                                  onChange={(e) => setPrice(e.target.value)}
                                />
                                <button
                                  onClick={() => handleList(item)}
                                  className="list-for-sale"
                                  disabled={item.isListed}
                                >
                                  {item.isListed ? "Listed" : "List"}
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </a>
                </a>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLister;
